/** @format */

import { Routes as Router, Route, BrowserRouter } from "react-router-dom";
import NotfoundPage from "./submodule/pages/NotFoundPage/NotfoundPage";
import Start from "./submodule/pages/Start/Start";
import Layout from "./submodule/components/admin/Layout/Layout";
import WebLayout from './components/Layout/Layout'
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import CatalogPage from "./pages/CatalogPage/CatalogPage";
import BillingPage from "./pages/BillingPage/BillingPage";
import SupportPage from "./pages/SupportPage/SupportPage";
import OrdersPage from "./pages/OrdersPage/OrdersPage";
import OrderDetailPage from "./pages/OrderDetailPage/OrderDetailPage";
import SubscriptionDetailPage from "./pages/SubscriptionDetailPage/SubscriptionDetailPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import BecomeCustomerPage from "./pages/BecomeCustomerPage/BecomeCustomerPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsConditionsPage from "./pages/TermsConditionsPage/TermsConditionsPage";
import LandingPage from "./pages/LandingPage/LandingPage";

const Routes = () => {
  return (
    <BrowserRouter >
      <Router>
        <Route path="*" element={<NotfoundPage />} />
        <Route path="/app/start" element={<Start app="portal" postLoginRedirectPath="/app/subscription" />} />
        <Route path="/app/signin" element={<LoginPage userType="client" />} />

        <Route path="/app" element={<Layout app="portal" />}>
          <Route path="/app/subscription" element={<SubscriptionPage />} />
          <Route
            path="/app/subscription/detail/:id"
            element={<SubscriptionDetailPage />}
          />
          <Route path="/app/catalog" element={<CatalogPage />} />
          <Route path="/app/billing" element={<BillingPage />} />
          <Route path="/app/support" element={<SupportPage />} />
          <Route path="/app/order" element={<OrdersPage />} />
          <Route path="/app/order/detail/:id" element={<OrderDetailPage />} />
        </Route>



        <Route path='/' element={<WebLayout />}>
        <Route path="" element={<LandingPage />} />
        </Route>
          <Route path="/become-customer" element={<BecomeCustomerPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-and-conditions" element={<TermsConditionsPage />} />

      </Router>
    </BrowserRouter>
  );
};

export default Routes;
