/** @format */
import axios from "axios";
import { useQuery } from 'react-query';
import { getAuthorizationHeader } from "../constants";

export function useOfferGetApi(catalog: string, enabled: boolean) {
    return useQuery(
        ['offers', catalog],
        () =>
            axios
                .get(`${process.env.REACT_APP_CLIENT_API_BASE}/offers`, {
                    headers: getAuthorizationHeader(),
                    params: { catalog },
                })
                .then((response) => response.data),
        {
            retry: 2,
            enabled, // Add enabled option
        }
    );
}

export function useQualificationsApi() {
  return useQuery('qualifications', () =>
          axios.get(`${process.env.REACT_APP_CLIENT_API_BASE}/qualifications`, {
            headers: getAuthorizationHeader(),
          })
              .then((response) => response.data), {
        retry: 2,
      }
  );
}
