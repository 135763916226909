/** @format */
import axios from "axios";
import { useQuery,useMutation, useQueryClient } from 'react-query';
import { getAuthorizationHeader } from "../constants";


export function useOrderGetApi() {
  return useQuery('orders', () => axios.get(`${process.env.REACT_APP_CLIENT_API_BASE}/orders`, { headers: getAuthorizationHeader() })
    .then((response) => {
     return response.data
  }),
{
    retry:2
  }
  );
}

export function useOrderPostApi() {
  const queryClient = useQueryClient();

  const mutation = useMutation(
      (params: {
        product_id: string;
        sku_id: string;
        quantity: number;
        term_duration: string;
        billing_plan: string;
        force?: boolean;
      }) => {
        // Build the URL with the force query parameter if it's provided
        const url = new URL(`${process.env.REACT_APP_CLIENT_API_BASE}/order`);
        if (params.force) {
          url.searchParams.append('force', 'true');
        }

        // Remove 'force' from params before sending the request body
        const { force, ...bodyParams } = params;

        return axios.post(
            url.toString(),
            [
              {
                product_id: bodyParams.product_id,
                sku_id: bodyParams.sku_id,
                quantity: String(bodyParams.quantity),
                term_duration: bodyParams.term_duration,
                billing_cycle: bodyParams.billing_plan,
              },
            ],
            { headers: getAuthorizationHeader() }
        );
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries('orders');
        },
      }
  );

  return mutation;
}
