import { Container, Grid, Typography, Button, Box } from "@mui/material";
import React from "react";
import { AppImages } from "../../images";
import "./Billing.scss";

const { email } = AppImages;

const BillingPage = () => {
    const billingPortalUrl = process.env.REACT_APP_BILLING_PORTAL_URL;

    return (
        <Container className="billing-container" maxWidth="md">
            <Grid container spacing={0} xs={12}>
                <Grid item xs={12}>
                    <Typography variant="h1" component="h1" className="billing-title">
                        Manage Account & Payments
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography className="billing-description">
                        Easily access and manage your billing information, view and pay invoices, update payment methods, and more.
                    </Typography>
                </Grid>
                <Grid item xs={12} className="billing-list">
                    <Typography component="li">View, download, and pay invoices</Typography>
                    <Typography component="li">Manage payment methods and automatic payments</Typography>
                    <Typography component="li">Update billing details</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Box display="flex" justifyContent="center" alignItems="center">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            className="btn-rounded btn-mid"
                            onClick={() => window.open(billingPortalUrl, "_blank", "noopener,noreferrer")}
                        >
                            Billing Portal
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={12} className="billing-help">
                    <Typography className="billing-help-text">
                        Something not working? Need Help? Contact Us!
                    </Typography>
                    <Box className="billing-email-box">
                        <Box className="mail-img">
                            <img src={email} alt="email" />
                        </Box>
                        <Typography className="first-sec-email">
                            <span>Email:</span>{" "}
                            <a href="mailto:o365@managedsolution.com">
                                o365@managedsolution.com
                            </a>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default BillingPage;
