import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Catalog.scss";
import SearchBar from "../../submodule/components/SearchBar/SearchBar";
import { useOfferGetApi, useQualificationsApi } from "../../api/CatalogApi";
import Loader from "../../components/Loader";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import TablePagination from "@mui/material/TablePagination";
import Accordion from "@mui/material/Accordion";
import Grid from "@mui/material/Grid";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Box, Button, Select, MenuItem } from "@mui/material";
import { CatalogItem, CatalogStyledBox } from "./CatalogPageStyles";
import BuySubscriptionModal from "./BuySubscriptionModal/BuySubscriptionModal";
import DuplicateSubscriptionModal from "./DuplicateSubscriptionModal/DuplicateSubscriptionModal";
import { debounceDelay, getPriceInFormat, getPurchaseOptionsAsObject } from "../../utils";
import { useOrderPostApi } from "../../api/OrderApi";
import AlertMessage from "../../submodule/components/AlertMessage/AlertMessage";

const CatalogPage = () => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedProduct, setProductSelected] = useState(null);
  const [messageAlert, setMessageAlert] = useState<{ type: string; message: string } | null>(null);
  const [selectedCatalog, setSelectedCatalog] = useState<string | null>(
      localStorage.getItem("selectedCatalog")
  );
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [duplicateData, setDuplicateData] = useState<any>(null);

  const { data: qualifications = [], isLoading: isQualificationsLoading } = useQualificationsApi();
  const saveOrder = useOrderPostApi();

  const getPrice = (product: any, priceKey: string) => {
    const billingOptionsAsObj = getPurchaseOptionsAsObject(product.purchase_options);

    if (billingOptionsAsObj.P1M?.monthly)
      return `${getPriceInFormat(billingOptionsAsObj.P1M.monthly[priceKey])}/month`;
    if (billingOptionsAsObj.P1Y?.monthly)
      return `${getPriceInFormat(billingOptionsAsObj.P1Y.monthly[priceKey])}/year`;
    if (billingOptionsAsObj.P1Y?.annual)
      return `${getPriceInFormat(billingOptionsAsObj.P1Y.annual[priceKey])}/year`;
    return "N/A";
  };

  useEffect(() => {
    const priorityCatalogs = ["Government", "Education", "Nonprofit", "Commercial"];

    if (qualifications.length > 0 && !selectedCatalog) {
      const defaultCatalog = priorityCatalogs.find((catalog) => qualifications.includes(catalog));
      setSelectedCatalog(defaultCatalog || qualifications[0]);
    }
  }, [qualifications, selectedCatalog]);

  useEffect(() => {
    if (selectedCatalog) {
      localStorage.setItem("selectedCatalog", selectedCatalog);
    }
  }, [selectedCatalog]);

  const { data = [], isLoading, isError } = useOfferGetApi(selectedCatalog || "", !!selectedCatalog);

  const products = useMemo(() => {
    if (searchText?.length) {
      return data.filter((dataItem: any) =>
          dataItem.title.toLowerCase().includes(searchText.toLowerCase().trim())
      );
    }
    return data;
  }, [searchText, data]);

  const onSearch = debounceDelay((e: any) => {
    setSearchText(e.target.value);
  }, 300);

  const handleChangePage = (
      _event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onBuySubscriptionClick = (product: any) => {
    setProductSelected(product);
  };

  const onCloseModal = () => {
    setProductSelected(null);
    setShowDuplicateModal(false); // Close DuplicateSubscriptionModal
  };

  const onSaveHandler = (data: any) => {
    saveOrder.mutate(data, {
      onSuccess() {
        onCloseModal();
        setMessageAlert({ type: "success", message: "Your order was accepted and is now being processed." });
      },
      onError(error: any) {
        // HTTP 409 response indicates a duplicate subscription
        if (error.response?.status === 409) {
          setDuplicateData(data);
          // onCloseModal();
          setMessageAlert({ type: "warning", message: "An active subscription already exists. Confirmation required." });
          setShowDuplicateModal(true);
        } else {
          setMessageAlert({ type: "error", message: "We could not create your order at this time. Please try again!" });
        }
      },
    });
  };

  const onDuplicateCreateNew = () => {
    if (!duplicateData) {
      return;
    }

    setShowDuplicateModal(false);
    onSaveHandler({ ...duplicateData, force: true });
    setDuplicateData(null);
  };

  const onDuplicateUpdateExisting = () => {
    setDuplicateData(null);
    setShowDuplicateModal(false);
    onCloseModal();

    navigate("/app/subscription");

    // TODO check if duplicateData has product_id, sku_id and correlate that to a subscriptionId
    // navigate(`/app/subscription/detail/${subscriptionId}`);
  };

  if (isQualificationsLoading || (isLoading && !!selectedCatalog)) {
    return (
        <div className="loader_wrapper">
          <Loader />
        </div>
    );
  }

  if (!selectedCatalog) {
    // If after loading Qualifications, no catalog is selected or available
    return <Typography variant="h5">No catalogs available.</Typography>;
  }

  if (isError) {
    return (
        <div>
          <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            {/* Drop-down and SearchBar */}
          </Box>
          <Typography variant="h5">Failed to load offers. Please try again later.</Typography>
        </div>
    );
  }

  return (
      <div>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
          <Select
              value={selectedCatalog}
              onChange={(e) => {
                setSelectedCatalog(e.target.value as string);
                setPage(0);
              }}
              style={{ minWidth: "200px", marginRight: "16px" }}
          >
            {qualifications.map((catalog: string) => (
                <MenuItem key={catalog} value={catalog}>
                  {catalog}
                </MenuItem>
            ))}
          </Select>
          <SearchBar onChange={onSearch} />
        </Box>
        <div className="catalog-panel">
          <div className="panel-light">
            {products.length > 0 ? (
                products
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((product: any, index: number) => (
                        <Accordion className="accordian" key={index}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography className="accordian-item-heading">{product.title}</Typography>
                          </AccordionSummary>
                          <AccordionDetails className="accordian-details">
                            <Box sx={{ flexGrow: 1 }}>
                              <Grid container spacing={2}>
                                {/* Product Description */}
                                <Grid item xs={12} md={8}>
                                  <CatalogItem>
                                    <Typography className="accordian-inner-para">
                                      {product.description}
                                    </Typography>
                                  </CatalogItem>
                                </Grid>
                                {/* Retail Price */}
                                <Grid item xs={12} md={8}>
                                  <CatalogItem>
                                    <CatalogStyledBox className="item-mspr light">
                                      <Typography className="item-label">MSRP:</Typography>
                                      <Typography className="item-label">
                                        {getPrice(product, "retail_price")}
                                      </Typography>
                                    </CatalogStyledBox>
                                  </CatalogItem>
                                </Grid>
                                {/* Client Discount */}
                                <Grid item xs={12} md={8}>
                                  <CatalogItem>
                                    <CatalogStyledBox className="item-mspr gray">
                                      <Typography className="item-label-bold">Client Discount:</Typography>
                                      <Typography className="item-label-bold">
                                        {getPrice(product, "sale_price")}
                                      </Typography>
                                    </CatalogStyledBox>
                                  </CatalogItem>
                                </Grid>
                                {/* Buy Subscription Button */}
                                <Grid item xs={12} md={4}>
                                  <CatalogItem sx={{ textAlign: `center` }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        onClick={() => onBuySubscriptionClick(product)}
                                        className="buy-sub"
                                        style={{
                                          paddingLeft: `30px`,
                                          paddingRight: `30px`,
                                          height: `50px`,
                                          borderRadius: `50px`,
                                          textTransform: `inherit`,
                                          font: `normal normal 500 18px/80px Raleway`,
                                        }}
                                    >
                                      Buy Subscriptions
                                    </Button>
                                    <Typography className="accordian-last-para">
                                      Annual commitment required
                                    </Typography>
                                  </CatalogItem>
                                </Grid>
                              </Grid>
                            </Box>
                          </AccordionDetails>
                        </Accordion>
                    ))
            ) : (
                <Typography sx={{ textAlign: "center" }} variant="h5">
                  No products found.
                </Typography>
            )}
          </div>
        </div>
        <TablePagination
            sx={{ background: "#e3e3e3", borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {selectedProduct && !showDuplicateModal && (
            <BuySubscriptionModal
                product={selectedProduct}
                onClose={onCloseModal}
                onSave={onSaveHandler}
                isSaving={saveOrder.isLoading}
            />
        )}
        {showDuplicateModal && (
            <DuplicateSubscriptionModal
                onClose={onCloseModal}
                onCreateNew={onDuplicateCreateNew}
                onUpdateExisting={onDuplicateUpdateExisting}
            />
        )}
        {messageAlert &&
            AlertMessage(true, messageAlert.message, messageAlert.type, () => setMessageAlert(null))}
      </div>
  );
};

export default CatalogPage;
