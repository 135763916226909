import React from "react";
import { Button, Typography, Box, Grid } from "@mui/material";
import { CatalogPageCustomDialog } from "../../../components/Dialog/Dialog";

type PropType = {
    onClose: () => void;
    onCreateNew: () => void;
    onUpdateExisting: () => void;
};

const DuplicateSubscriptionModal = ({ onClose, onCreateNew, onUpdateExisting }: PropType) => {
    return (
        <CatalogPageCustomDialog onClose={onClose}>
            <Box sx={{ textAlign: "center" }} >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                    CONFIRM DUPLICATE SUBSCRIPTION
                </Typography>
                <Grid container spacing={2} direction="column" justifyContent="center">
                    <Grid item xs>
                        <Typography variant="body2" sx={{ marginBottom: "0.5em" }}>
                            <em>We found a similar active subscription</em>
                        </Typography>
                    </Grid>
                    <Grid item xs marginBottom="0.5em">
                        <Typography variant="body1" sx={{ textAlign: "left" }}>
                            To modify or increase licenses, click on <Typography sx={{ display: "inline", color: "#ee7623" }}><b>UPDATE EXISTING</b></Typography> to manage your current subscription.
                        </Typography>
                    </Grid>
                    <Grid item xs spacing={6} marginBottom="0.5em">
                        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "2em", color: "#444", backgroundColor: "#fef3e2", padding: "0.25em", border: "1px solid #ffa726", borderRadius: "1em" }}>
                            <Typography fontSize="2em" sx={{ paddingLeft: "0.25em", flexShrink: 0 }}>
                                ⚠️
                            </Typography>
                            <Typography variant="body1" padding="0.5em" sx={{ flexGrow: 1, textAlign: "left" }}>
                                Creating a new subscription starts a new commitment period and may override existing discounts.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justifyContent="space-between" sx={{flexDirection: { xs: 'row', sm: 'row' }}}>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Box sx={{ textAlign: "center" }}>
                            <Button
                                variant="outlined"
                                onClick={onCreateNew}
                                sx={{
                                    padding: "0.5em 1.5em",
                                    borderRadius: "2em",
                                    fontSize: "1em",
                                    textTransform: "none",
                                    borderColor: "#ee7623",
                                    color: "#ee7623",
                                }}
                            >
                                Create New
                            </Button>
                            <Typography variant="caption" sx={{ display: "block", marginTop: "0.25em" }}>
                                &nbsp;
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sx={{ flexGrow: 1 }}>
                        <Button
                            variant="contained"
                            onClick={onUpdateExisting}
                            sx={{
                                padding: "0.5em 1.5em",
                                borderRadius: "2em",
                                fontSize: "1em",
                                textTransform: "none",
                            }}
                        >
                            Update Existing
                        </Button>
                        <Typography variant="caption" sx={{ display: "block", marginTop: "0.25em", color: "#ee7623" }}>
                            (Recommended)
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </CatalogPageCustomDialog>
    );
};

export default DuplicateSubscriptionModal;