/** @format */
import axios from "axios";
import { useMutation } from 'react-query';

    interface  requestBody {
      source: string,
      page: string,
      data: {
        Email: string,
        "Phone Number": string,
        Subject: string,
        Message: string,
        "How would you like us to contact you?": string,
      },
    }
export function useMessageApi (){
const mutation = useMutation((params:requestBody) => {
    return axios.post( `${process.env.REACT_APP_API_BASE}/notify/v1/portalmessage`,{...params}
)},{
    retry:2
})
return mutation
}