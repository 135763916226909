import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TuneIcon from "@mui/icons-material/Tune";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import ReceiptIcon from "@mui/icons-material/Receipt"
import {Modules} from "./submodule/constants/constants";

export const SideBarRoutesList = [
  {
    text: "Subscriptions",
    icon: CheckCircleOutlineIcon,
    to: "/app/subscription",
    activeSideBar: Modules.Subscription,
  },
  {
    text: "Catalog",
    icon: ContentCopyIcon,
    to:"/app/catalog",
    activeSideBar: Modules.Catalog,
  },
  {
    text: "Orders",
    icon: TuneIcon,
    to:"/app/order",
    activeSideBar: Modules.Order,
  },
  {
    text: "Billing",
    icon: ReceiptIcon,
    to:"/app/billing",
    activeSideBar: Modules.Billing,
  },
  {
    text: "Support",
    icon: HeadsetMicIcon,
    to:"/app/support",
    activeSideBar: Modules.Support,
  }
];


export function getAuthorizationHeader() {
  return {
    Authorization: `Bearer ${sessionStorage.getItem("session_guid")}`
  };
}
