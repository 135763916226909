import * as React from "react";
import { styled } from "@mui/system";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton"; // Updated from ListItem
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useLocation } from "react-router-dom"; // Removed LinkProps from the import
import { Typography } from "@mui/material";
import { SubModAppImages, Website_URL } from "../../../constants/constants";
import "./Sidebar.scss";
import { SideBarRoutesList } from "../../../../constants";

const drawerWidth = 280;

const { logo } = SubModAppImages;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6, 4, 4, 3),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(() => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
}));

const Sidebar = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathNameSplit = pathname.split("/");
  const path = pathNameSplit[pathNameSplit.length - 1];

  const validSideBarValues = [
    "logs",
    "organization",
    "subscription",
    "order",
    "organizations",
    "settings",
  ];

  let highlightedSideBar = location.state ? location.state.activeSideBar : path;

  for (const item of pathNameSplit) {
    if (validSideBarValues.includes(item)) {
      highlightedSideBar = item;
      break;
    }
  }

  if (!validSideBarValues.includes(highlightedSideBar)) {
    highlightedSideBar = path;
  }

  function handleShowSideBar() {
    let element = document.querySelectorAll(
        ".MuiDrawer-root .MuiPaper-root"
    )[0] as HTMLInputElement | null;
    if (element != null) {
      element.style.left = "-100%";
    }
  }

  return (
      <div>
        <Drawer className="responsive-sidebar" variant="permanent">
          <DrawerHeader>
            <CloseIcon
                fontSize="large"
                onClick={handleShowSideBar}
                className="responsive-menu-hamburger"
            />
            <a href={Website_URL} className="logo">
              <img className="app-logo" alt="logo" src={logo} />
            </a>
          </DrawerHeader>
          <List className="sidebarnav">
            {SideBarRoutesList.map((item) => {
              const { text, to, icon: Icon, activeSideBar, child } = item as any;
              return (
                  <ListItemButton
                      component={Link} // Use Link directly as the component
                      to={to} // Pass 'to' for react-router-dom's Link
                      key={text}
                      state={{ activeSideBar: activeSideBar }}
                      className={
                        highlightedSideBar === activeSideBar || highlightedSideBar === child
                            ? "active"
                            : ""
                      }
                  >
                    {Icon && (
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={<Typography className="navLink">{text}</Typography>}
                    />
                  </ListItemButton>
              );
            })}
          </List>
        </Drawer>
      </div>
  );
};

export default Sidebar;
